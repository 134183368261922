import React from 'react'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import { Box } from 'theme-ui'

export default () => (
  <>
    <Seo title='Contact Us' />
    <Divider />
    <Stack>
      <Box sx={{ width: '960px', m: 'auto' }}>
        <Main>
          <PageTitle
            header='Get in touch'
            subheader='Please use the form below to send us a message'
          />
          <Divider />
          <ContactForm />
        </Main>
      </Box>
    </Stack>
  </>
)
