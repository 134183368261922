import { useState } from 'react' // useEffect, useCallback
import Amplify, { API } from 'aws-amplify'
import awsconfig from '../../../../../../src/aws-exports.js'
Amplify.configure(awsconfig)

const contactForm = () => {
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  // const [values, setValues] = useState({})
  const [success, setSuccess] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()
    setSubmitting(true)
    const data = new FormData(e.target)
    const newValues = {}
    for (let pair of data.entries()) {
      newValues[pair[0]] = pair[1]
    }

    const { name, email, subject, message } = newValues

    try {
      await API.post('viadogWebsiteREST', '/contact', {
        body: {
          name,
          email,
          subject,
          message
        }
      })

      // setValues(newValues)
      setSubmitted(true)
      setSuccess(true)
    } catch (err) {
      console.log({ err })
      setSubmitted(false)
      setSuccess(false)
    }
    setSubmitting(false)

    // const newValues = {}
    // for (var pair of data.entries()) {
    //   newValues[pair[0]] = pair[1]
    // }
  }

  // const sendValues = useCallback(() => {
  //   /**
  //    * Code for submitting form values
  //    * via an external API goes here.
  //    */
  //   setTimeout(function() {
  //     console.log(values)
  //     setSuccess(true)
  //     setSubmitting(false)
  //   }, 2000)
  // }, [values])

  // useEffect(() => {
  //   if (submitted) {
  //     sendValues()
  //   }
  // }, [submitted, sendValues])

  return {
    handleSubmit,
    submitting,
    submitted,
    success
  }
}

export default contactForm
